let parents = document.querySelectorAll(".has-children>a");
if (parents) {
  const mediaQuery = window.matchMedia("(max-width: 1199px)");
  if (mediaQuery.matches) {
    for (let i = 0; i < parents.length; i++) {
      parents[i].addEventListener("click", function (e) {
        e.preventDefault();
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }
}
