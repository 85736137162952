document.querySelectorAll(".file-content input[type=file]").forEach((input) => {
  input.addEventListener("change", function () {
    let fileVal = this.files.length > 0 ? this.files[0].name : this.placeholder;
    this.nextElementSibling.innerHTML = fileVal;
  });
});

document.querySelectorAll(".file-content input[type=file]").forEach((input) => {
  input.addEventListener("change", function () {
    const sibling = this.nextElementSibling;

    if (this.files.length > 0) {
      sibling.innerHTML = this.files[0].name;
      sibling.classList.remove("placeholder-value");
    } else {
      sibling.innerHTML = this.placeholder;
      sibling.classList.add("placeholder-value");
    }
  });
});
