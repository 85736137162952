let scrollPosition = window.scrollY;
let headerContainer = document.querySelector("header");
if (scrollPosition >= 150) {
  headerContainer.classList.add("fixed");
} else {
  headerContainer.classList.remove("fixed");
}
window.addEventListener("scroll", function () {
  scrollPosition = window.scrollY;
  if (scrollPosition >= 150) {
    headerContainer.classList.add("fixed");
  } else {
    headerContainer.classList.remove("fixed");
  }
});
