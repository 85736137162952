import Swiper, { Pagination, Navigation, Autoplay, A11y } from "swiper";

const mainSwiper = new Swiper(".main-slider .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  spaceBetween: 0,
  loop: true,
  speed: 500,
  autoplay: {
    delay: 5000,
  },
  navigation: {
    nextEl: ".main-slider .swiper-button-next",
    prevEl: ".main-slider .swiper-button-prev",
  },
  pagination: {
    el: ".main-slider .swiper-pagination",
    clickable: true,
  },
});

const servicesSwiper = new Swiper(".services-slider .swiper", {
  modules: [Autoplay, A11y, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    1199: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".services-slider .slider-pagination",
    clickable: true,
  },
});

const clientsSwiper = new Swiper(".clients-slider .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    767: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 6,
      spaceBetween: 20,
    },
    1199: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".clients-slider .slider-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".clients-slider .slider-button-next",
    prevEl: ".clients-slider .slider-button-prev",
  },
});

const articlesSwiper = new Swiper(".articles-slider .swiper", {
  modules: [Autoplay, A11y, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    991: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    1199: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".articles-slider .slider-pagination",
    clickable: true,
  },
});

const testimonialsSwiper = new Swiper(".testimonials-slider .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    1199: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".testimonials-slider .slider-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".testimonials-slider .slider-button-next",
    prevEl: ".testimonials-slider .slider-button-prev",
  },
});

const projectsSwiper = new Swiper(".projects-slider .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    1199: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".projects-slider .slider-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".projects-slider .slider-button-next",
    prevEl: ".projects-slider .slider-button-prev",
  },
});

const projectSwiper = new Swiper(".project-imgs .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  spaceBetween: 0,
  loop: true,
  speed: 500,
  autoplay: {
    delay: 5000,
  },
  navigation: {
    nextEl: ".project-imgs .swiper-button-next",
    prevEl: ".project-imgs .swiper-button-prev",
  },
  pagination: {
    el: ".project-imgs .swiper-pagination",
    clickable: true,
  },
});

const certificatesSwiper = new Swiper(".certificates-slider .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    1199: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".certificates-slider .slider-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".certificates-slider .slider-button-next",
    prevEl: ".certificates-slider .slider-button-prev",
  },
});

const gallerySwiper = new Swiper(".gallery-slider .swiper", {
  modules: [Autoplay, A11y, Navigation, Pagination],
  a11y: {
    enabled: false,
  },
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    767: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 4,
      spaceBetween: 15,
    },
    1199: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
  pagination: {
    el: ".gallery-slider .slider-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".gallery-slider .slider-button-next",
    prevEl: ".gallery-slider .slider-button-prev",
  },
});
