import intlTelInput from "intl-tel-input";

const utilsScript = require("intl-tel-input/build/js/utils.js");

const inputs = document.querySelectorAll("input[intl-tel]");
if (inputs) {
  inputs.forEach((input) => {
    intlTelInput(input, {
      utilsScript: utilsScript,
      initialCountry: "sa",
      preferredCountries: ["sa", "qa", "eg"],
    });
  });
}
