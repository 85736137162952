let scrollBtns = document.querySelectorAll(".scroll-btn");

if (scrollBtns) {
  scrollBtns.forEach((scrollBtn) => {
    const id = scrollBtn.getAttribute("href");
    const element = document.querySelector(id);
    scrollBtn.addEventListener("click", function (e) {
      e.preventDefault();
      // element.scrollIntoView({ behavior: "smooth" });
      const offsetTop = element.offsetTop - 75;
      scroll({
        top: offsetTop,
        behavior: "smooth",
      });
    });
  });
}

/* Scroll Top */
let scrollPosition = window.scrollY;
let arrowTop = document.querySelector(".arrow-top");

if (scrollPosition >= 500) {
  arrowTop.style.display = "block";
} else {
  arrowTop.style.display = "none";
}
window.addEventListener("scroll", function () {
  scrollPosition = window.scrollY;
  if (scrollPosition >= 500) {
    arrowTop.style.display = "block";
  } else {
    arrowTop.style.display = "none";
  }
});

arrowTop.addEventListener("click", function () {
  window.scrollTo({ top: 0, behavior: "smooth" });
});
