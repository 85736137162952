const filters = document.querySelectorAll(".project-filter");

filters.forEach((filter) => {
  filter.addEventListener("click", function () {
    filters.forEach((filter) => {
      filter.classList.remove("active");
    });
    filter.classList.add("active");
    let selectedFilter = filter.getAttribute("data-filter");
    console.log(selectedFilter);
    let itemsToHide = document.querySelectorAll(
      `.projects_inner-grid .filterd-item:not([data-filter='${selectedFilter}'])`
    );
    let itemsToShow = document.querySelectorAll(
      `.projects_inner-grid [data-filter='${selectedFilter}']`
    );

    if (selectedFilter == "all") {
      itemsToHide = [];
      itemsToShow = document.querySelectorAll(".projects_inner-grid [data-filter]");
    }

    itemsToHide.forEach((el) => {
      el.classList.add("hide");
      el.classList.remove("show");
    });

    itemsToShow.forEach((el) => {
      el.classList.remove("hide");
      el.classList.add("show");
    });
  });
});
