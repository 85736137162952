const links = document.querySelectorAll(".landing-nav .nav-list>li>a");

const sections = [];
const header = document.querySelector(".main-header");
const body = document.body;

/*landing links*/
links.forEach(function (elem) {
  elem.addEventListener("click", smoothScroll);
  const id = elem.getAttribute("href");
  sections.push(document.querySelector(id));
});

function smoothScroll(e) {
  e.preventDefault();
  const link = this.getAttribute("href");
  const offsetTop = document.querySelector(link).offsetTop - 75;
  scroll({
    top: offsetTop,
    behavior: "smooth",
  });

  header.classList.remove("opend_nav");
  body.classList.remove("overflow");
}

window.addEventListener("scroll", () => {
  if (sections && sections.length > 0) {
    let current = sections[0].getAttribute("id");
    sections.forEach((section) => {
      let sectionTop = section.offsetTop;
      if (scrollY >= sectionTop - 100) {
        current = section.getAttribute("id");
      }
    });
    links.forEach((links) => {
      links.classList.remove("active");
      document
        .querySelector(".landing-nav .nav-list>li>a[href*= " + current + "]")
        .classList.add("active");
    });
  }
});
